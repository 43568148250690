import { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import API from "services/api";
import Loader from "utils/Loader";
import SnackBar from "utils/SnackBar";
import Form from "../../components/DefaultForm/Form";
import { monitoringNDDFields } from "./formFields";

const MonitoringNDD = () => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);

  const [formData, setFormData] = useState({
    latitude: "Fetching location...",
    longitude: "Fetching location...",
  });

  const [snackBarMessage, setSnackBarMessage] = useState({
    text: "",
    type: "",
  });

  const updatedFields = monitoringNDDFields.map(field => {
    if (field.name === 'latitude' || field.name === 'longitude') {
      return {
        ...field,
        value: formData[field.name],
        defaultValue: formData[field.name]
      };
    }
    return field;
  });


  const fetchLocation = () => {
    if (navigator.geolocation) {
      setLoadingStatus(true);
      navigator.geolocation.getCurrentPosition(
          (position) => {
            const lat = position.coords.latitude.toFixed(6);
            const lng = position.coords.longitude.toFixed(6);
            console.log("Location fetched:", { lat, lng });

            setFormData(prevData => {
              const newData = {
                ...prevData,
                latitude: lat,
                longitude: lng,
              };
              return newData;
            });

            setLoadingStatus(false);
          },
          (error) => {
            console.error("Geolocation error:", error);
            setFormData(prevData => ({
              ...prevData,
              latitude: "Location not available",
              longitude: "Location not available",
            }));
            setShowSnackBar(true);
            setSnackBarMessage({
              text: "Failed to get location. Please ensure location services are enabled.",
              type: "error",
            });
            setLoadingStatus(false);
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          }
      );
    } else {
      console.log("Geolocation not supported");
      setFormData(prevData => ({
        ...prevData,
        latitude: "Geolocation not supported",
        longitude: "Geolocation not supported",
      }));
      setShowSnackBar(true);
      setSnackBarMessage({
        text: "Geolocation is not supported by this browser.",
        type: "error",
      });
    }
  };

  useEffect(() => {
    fetchLocation();
  }, []);

  const handleData = async (submittedFormData) => {
    // Get all fields that should be visible based on their showIf conditions
    const getVisibleFields = () => {
      return monitoringNDDFields.filter(field => {
        // If field has no showIf condition, it's always visible
        if (!field.showIf) {
          return true;
        }

        // Check if the field should be shown based on current form values
        try {
          return field.showIf(submittedFormData);
        } catch (error) {
          console.error(`Error evaluating showIf for field ${field.name}:`, error);
          return false;
        }
      });
    };

    // Only validate required fields that are currently visible
    const visibleFields = getVisibleFields();
    console.log('Visible fields:', visibleFields.map(f => f.name));
    
    const requiredFields = visibleFields.filter(field => field.required);
    console.log('Required visible fields:', requiredFields.map(f => f.name));
    
    const missingFields = requiredFields.filter(field => {
      const value = submittedFormData[field.name];
      const isMissing = value === undefined || value === null || value === '';
      console.log(`Checking field ${field.name}:`, { value, isMissing });
      return isMissing;
    });

    if (missingFields.length > 0) {
      console.log("Missing required fields:", missingFields);
      setShowSnackBar(true);
      setSnackBarMessage({
        text: "Please fill all required fields",
        type: "error",
      });
      return;
    }

    try {
      // Remove values from hidden fields before submission
      const cleanedFormData = {};
      const visibleFieldNames = visibleFields.map(field => field.name);
      
      Object.keys(submittedFormData).forEach(key => {
        if (visibleFieldNames.includes(key)) {
          cleanedFormData[key] = submittedFormData[key];
        }
      });

      console.log('Submitting cleaned form data:', cleanedFormData);
      setLoadingStatus(true);
      const response = await API.submitMonitoringNDD(cleanedFormData);

      if (response.success) {
        setShowSnackBar(true);
        setSnackBarMessage({
          text: "Form submitted successfully!",
          type: "success",
        });
        
        // Add timeout to allow snackbar to be visible before refresh
        setTimeout(() => {
          window.location.reload();
        }, 2000); // 2 seconds delay
        
      } else {
        throw new Error("API returned success: false");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setShowSnackBar(true);
      setSnackBarMessage({
        text: "Error submitting form",
        type: "error",
      });
    } finally {
      setLoadingStatus(false);
    }
  };

  const handleCloseSnackBar = () => {
    setShowSnackBar(false);
  };

  const formKey = JSON.stringify(formData);

  return (
      <>
        <SnackBar
            message={snackBarMessage.text}
            open={showSnackBar}
            handleClose={handleCloseSnackBar}
            type={snackBarMessage.type}
        />
        <div className="contact-main-container container-fluid">
          {loadingStatus && <Loader loading={loadingStatus} />}
          <Form
              key={formKey}
              initialValues={formData}
              formHeading="Monitoring Checklist for NDD February 2025 - Service Delivery Point"
              fields={updatedFields}
              title="Submit"
              title2="Save Draft"
              secondIcon={<EditOutlinedIcon fontSize="8px" />}
              firstIcon={<DoneIcon fontSize="8px" />}
              onClick={(data) => {
                handleData(data);
              }}
              isShowCancel={false}
          />
        </div>
      </>
  );
};

export default MonitoringNDD;
