import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FaCheck } from "react-icons/fa";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CircularProgress from "@mui/material/CircularProgress";

const Form = ({
  formHeading,
  fields,
  title2,
  title,
  firstIcon,
  secondIcon,
  onClick,
  isShowCancel,
  isDisabled,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Initialize form data based on fields array
  const initialFormData = fields.reduce((acc, field) => {
    if (field.type === "number") {
      acc[field.name] = Number(field.value) || "";
    } else if (field.type === "radio" && field.value === "true") {
      acc[field.name] = true;
    } else if (field.type === "radio" && field.value === "false") {
      acc[field.name] = false;
    } else {
      acc[field.name] = field.value || "";
    }
    return acc;
  }, {});

  // Initialize errors object for each field
  const initialErrors = fields.reduce((acc, field) => {
    acc[field.name] = "";
    return acc;
  }, {});

  // State for form data and errors
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrors);

  // Add state for location
  const [location, setLocation] = useState({ latitude: '', longitude: '' });
  const [locationError, setLocationError] = useState('');
  const [locationLoading, setLocationLoading] = useState(false);

  // Add state for address
  const [address, setAddress] = useState('');

  // Function to get address from coordinates
  const getAddressFromCoords = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const data = await response.json();
      return data.display_name;
    } catch (error) {
      console.error('Error fetching address:', error);
      return 'Location found, but address could not be determined';
    }
  };

  // Modified getCurrentLocation function
  const getCurrentLocation = () => {
    if ("geolocation" in navigator) {
      setLocationLoading(true);
      // Define geolocation options with increased timeout
      const options = {
        enableHighAccuracy: true,
        timeout: 20000, // 20 seconds timeout
        maximumAge: 0,
      };

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          
          // Optionally, get a human-readable address from the coordinates
          const locationAddress = await getAddressFromCoords(latitude, longitude);
          setAddress(locationAddress);

          // Update form data with the obtained location information
          setFormData((prev) => ({
            ...prev,
            latitude: latitude.toString(),
            longitude: longitude.toString(),
            location: locationAddress,
          }));

          setLocationError("");
          setLocationLoading(false);
        },
        (error) => {
          console.error("Geolocation error: ", error);
          // Provide a user-friendly error message
          setLocationError("Error getting location: " + error.message);
          setLocationLoading(false);
        },
        options
      );
    } else {
      setLocationError("Geolocation is not supported by your browser");
    }
  };

  // Handle input change
  const handleChange = (event) => {
    const { name, type, value, checked } = event.target;

    let newFormData = { ...formData };
    let newErrors = { ...errors };

    if (type === "checkbox") {
      if (checked) {
        newFormData[name] = [...(newFormData[name] || []), value];
      } else {
        newFormData[name] = newFormData[name].filter((item) => item !== value);
      }
    } else if (type === "radio") {
      if (value === "true") {
        newFormData[name] = true;
      } else if (value === "false") {
        newFormData[name] = false;
      } else {
        newFormData[name] = value;
      }
    } else if (type === "number") {
      newFormData[name] = type === "number" ? Number(value) : value;
    } else {
      newFormData[name] = value;
    }

    // Validate field based on type and update errors
    const field = fields.find((f) => f.name === name);
    if (field) {
      if (!newFormData[name] && newFormData[name] !== false) {
        // Ensure that false does not trigger an error
        newErrors[name] = "This field is required";
      } else if (field.type === "number") {
        const numericValue = value.trim();
        const numericLength = numericValue.length;

        if (numericLength < field.minLength) {
          newErrors[name] = `value must be at least ${field.minLength}`;
        } else if (numericLength > field.maxLength) {
          newErrors[name] = `value must be no more than ${field.maxLength}`;
        } else {
          newErrors[name] = "";
        }
      } else {
        newErrors[name] = "";
      }
    }
    setFormData(newFormData);
    setErrors(newErrors);
  };

  const isFieldVisible = (field, formData) => {
    // If the field has a visibility condition, evaluate it
    if (field.showIf) {
      return field.showIf(formData);
    }
    // If no visibility condition, field is always visible
    return true;
  };

  const validate = () => {
    let tempErrors = {};

    fields.forEach((field) => {
      // Only validate if the field is currently visible/applicable
      if (isFieldVisible(field, formData)) {
        const fieldValue = formData[field.name];

        // Handle validation for radio buttons (single select)
        if (field.type === "radio") {
          if (fieldValue === undefined || fieldValue === "") {
            tempErrors[field.name] = `This field is required`;
          }
        }
        // Handle validation for general required fields
        else if (field.required && !fieldValue && fieldValue !== false) {
          tempErrors[field.name] = `This field is required`;
        }

        // Handle validation for "Others" fields
        if (field.name.endsWith("-Others")) {
          const parentFieldName = field.name.replace("-Others", "");
          const parentValue = formData[parentFieldName] || [];
          const selectedValues = Array.isArray(parentValue) ? parentValue : [parentValue];

          if (selectedValues.includes("others")) {
            if (!fieldValue || fieldValue.trim() === "") {
              tempErrors[field.name] = "Please specify the other option";
            }
          } else {
            delete tempErrors[field.name];
          }
        }
      }
    });

    console.log("Validation Errors:", tempErrors);
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Helper function to convert array values to string format
  const formatArrayValue = (value, othersValue) => {
    if (!Array.isArray(value)) return value;
    
    const regularValues = value.filter(v => v !== "Others" && v !== "others");
    const hasOthers = value.includes("Others") || value.includes("others");
    
    if (hasOthers && othersValue) {
      return [...regularValues, `Others: ${othersValue}`].join(", ");
    }
    
    return regularValues.join(", ");
  };

  // Function to prepare payload before submission
  const preparePayload = (data) => {
    const payload = { ...data };
    
    // Process all fields
    Object.keys(payload).forEach(key => {
      // Skip processing latitude, longitude and other numeric fields
      if (key === 'latitude' || key === 'longitude' || 
          key === 'childrenEnrollAwc' || key === 'attendenceAwc' ||
          key === 'mobileMonitorOfficer' || key === 'mobileRespondent') {
        return;
      }

      // If it's an Others field, skip it as we'll handle it with the main field
      if (key.endsWith('-Others')) {
        delete payload[key];
        return;
      }

      // Handle array values
      if (Array.isArray(payload[key])) {
        const othersValue = payload[`${key}-Others`];
        payload[key] = formatArrayValue(payload[key], othersValue);
      }
      // Handle single select with Others
      else if (payload[key] === 'Others' || payload[key] === 'others') {
        const othersValue = payload[`${key}-Others`];
        payload[key] = `Others: ${othersValue}`;
      }
    });

    return payload;
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("🚀 handleSubmit triggered!", formData);

    if (validate()) {
      console.log("✅ Validation passed. Preparing payload...");
      const finalPayload = preparePayload(formData);
      console.log("📦 Final payload:", finalPayload);
      onClick(finalPayload);
    } else {
      console.log("❌ Validation failed. Not submitting.");
    }
  };

  const resetFormValue = () => {
    setFormData(initialFormData);
  };

  const renderFields = (formFields, formData, errors) => {
    return formFields.map((field, i) => {
      const isVisible = field.showIf ? field.showIf(formData) : true;

      if (!isVisible) {
        return null;
      }

      if (field.type === "location") {
        return (
          <Grid key={i} item xs={12} sm={12} md={field?.size} lg={field?.size}>
            <div className={`${errors[field.name] ? "errorBack" : "noerror"}`}>
              <label className="classForText py-1" htmlFor={field.id}>
                <span className="colorApplyForStar">*</span> {field.label}
              </label>
              
              <div className="location-container border rounded p-3">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={getCurrentLocation}
                  startIcon={<LocationOnIcon />}
                  size="small"
                  sx={{ mb: 2 }}
                >
                  Get Current Location
                </Button>

                {/* Show the spinner only inside the location container */}
                {locationLoading && (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress size={24} />
                  </div>
                )}

                {locationError && (
                  <div className="alert alert-danger mt-2" role="alert">
                    {locationError}
                  </div>
                )}

                {address && (
                  <div className="alert alert-success mt-2" role="alert">
                    📍 {address}
                  </div>
                )}

                {formData.latitude && formData.longitude && (
                  <div className="mt-2" style={{ height: '200px', width: '100%' }}>
                    <iframe
                      title="location-map"
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      style={{ border: 0 }}
                      src={`https://www.openstreetmap.org/export/embed.html?bbox=${formData.longitude},${formData.latitude},${formData.longitude},${formData.latitude}&layer=mapnik&marker=${formData.latitude},${formData.longitude}`}
                    />
                  </div>
                )}
              </div>

              {errors[field.name] && (
                <h6 className="py-2 errormsg">{errors[field.name]}</h6>
              )}
            </div>
          </Grid>
        );
      }

      return (
        <Grid key={i} item xs={12} sm={12} md={field?.size} lg={field?.size}>
          {field?.heading && (
            <div className="my-2">
              <p className="text-secondary">{field?.heading}</p>
            </div>
          )}
          {field?.subHeading && (
            <div className="my-2">
              <p className="text-secondary" style={{ fontSize: '12px' }}>{field?.subHeading}</p>
            </div>
          )}
          <div className={`${errors[field.name] ? "errorBack" : "noerror"}`}>
            <label className="classForText py-1" htmlFor={field.id}>
              <span className="colorApplyForStar">*</span> 
              {typeof field.label === 'function' ? field.label(formData) : field.label}
              {field.hint && (
    <span className="text-muted d-block small">
      {field.hint}
    </span>
  )}
            </label>
            {field.type === "date" && (
              <>
                <input
                  size="small"
                  type="date"
                  id={field.id}
                  name={field.name}
                  onChange={handleChange}
                  className="form-control adjustTextSize text-dark"
                  placeholder={field.placeholder}
                  value={formData[field.name]}
                />
                {errors[field.name] && (
                  <h6 className=" py-2 errormsg">{errors[field.name]}</h6>
                )}
              </>
            )}
            {field.type === "text" && (
              <>
                <input
                  type={field?.type === "text" ? "text" : "email"}
                  id={field.id}
                  name={field.name}
                  onChange={handleChange}
                  className="form-control adjustTextSize text-dark rounded"
                  placeholder={field.placeholder}
                  value={formData[field.name]}
                />
                {errors[field.name] && (
                  <h6 className="py-2 errormsg">{errors[field.name]}</h6>
                )}
              </>
            )}
            {field.type === "number" && (
              <>
                <input
                  type="number"
                  id={field.id}
                  name={field.name}
                  onChange={handleChange}
                  className="form-control adjustTextSize text-dark rounded"
                  placeholder={field.placeholder}
                  value={formData[field.name]}
                />
                {errors[field.name] && (
                  <h6 className="py-2 errormsg">{errors[field.name]}</h6>
                )}
              </>
            )}
            {field.type === "radio" && (
              <>
                {(typeof field.options === 'function' ? field.options(formData) : field.options || []).map((option) => (
                  <div key={option.value} className="form-check">
                    <input
                      className="form-check-input custom-radio"
                      type="radio"
                      name={field.name}
                      id={`${field.name}-${option.value}`}
                      value={option.value}
                      checked={
                        formData[field.name] === option.value ||
                        formData[field.name] ===
                          (option.value === "true"
                            ? true
                            : option.value === "false"
                            ? false
                            : option.value)
                      }
                      onChange={handleChange}
                    />
                    <label
                      className="adjustTextSize mt-1 adjustSpace form-check-label ml-3"
                      htmlFor={`${field.name}-${option.value}`}
                    >
                      {option.label}
                    </label>
                  </div>
                ))}
                {errors[field.name] && (
                  <h6 className="py-2 errormsg radioError">
                    {errors[field.name]}
                  </h6>
                )}
              </>
            )}
            {field.type === "radio" &&
              formData[field.name] === "Others" &&
              field.subFields &&
              renderFields(field.subFields, formData, errors)}
            {field.type === "checkbox" && (
              <>
                {(typeof field.options === 'function' ? field.options(formData) : field.options).map((option) => (
                  <div key={option.value} className="form-check">
                    <input
                      className="form-check-input custom-checkbox"
                      type="checkbox"
                      name={field.name}
                      id={option.value}
                      value={option.value}
                      checked={formData[field.name][option.value]}
                      onChange={handleChange}
                    />
                    <label
                      className="adjustTextSize mt-1 adjustSpace form-check-label"
                      htmlFor={option.value}
                    >
                      {option.label}
                    </label>
                  </div>
                ))}
                {errors[field.name] && (
                  <h6 className="py-2 errormsg radioError">
                    {errors[field.name]}
                  </h6>
                )}
              </>
            )}
            {field.type === "checkbox" &&
              formData[field.name]?.includes("Others") &&
              field.subFields &&
              renderFields(field.subFields, formData, errors)}
            {field.type === "select" && (
              <>
                <select
                  id={field.id}
                  name={field.name}
                  onChange={handleChange}
                  className="form-control adjustTextSize text-dark rounded"
                  value={formData[field.name]}
                >
                  <option value="">Select an option</option>
                  {field.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors[field.name] && (
                  <h6 className="py-2 errormsg">{errors[field.name]}</h6>
                )}
              </>
            )}
            {((field.name === "designation" &&
              formData.designation === "other") ||
              (field.name === "testScreenForAnemia" &&
                formData.testScreenForAnemia === "other") ||
              (field.name === "AlbendazoleReceive" &&
                formData.AlbendazoleReceive === "other") ||
              (field?.name === "AMBReport" && formData.AMBReport === false)) &&
              field.subField &&
              field.subField.map((subField, index) => (
                <div key={index}>
                  <label className="classForText" htmlFor={subField.id}>
                    <span className="colorApplyForStar">*</span>{" "}
                    {subField.label}
                  </label>
                  <input
                    type="text"
                    id={subField.id}
                    name={subField.name}
                    onChange={handleChange}
                    className="form-control adjustTextSize text-dark rounded"
                    placeholder={subField.placeholder}
                    value={formData[subField.name] || ""}
                  />
                  {errors[subField.name] && (
                    <span className=" font-weight-bold mt-1">
                      {errors[subField.name]}
                    </span>
                  )}
                </div>
              ))}
            {((field.name === "last3Months" && formData.last3Months === true) ||
              (field.name === "last6Months" && formData.last6Months === true) ||
              (field.name === "next3Months" && formData.next3Months === true) ||
              (field.name === "next6Months" &&
                formData.next6Months === true)) &&
              field.IFAtype && (
                <div className="mt-3 ml-2">
                  <label className="classForText">{field.IFAtype.label}</label>
                  {field.IFAtype.options.map((option) => (
                    <div key={option.value} className="form-check">
                      <input
                        className="form-check-input custom-checkbox"
                        type="checkbox"
                        name={field.IFAtype.name}
                        id={option.value}
                        value={option.value}
                        checked={formData[field.IFAtype.name]?.includes(
                          option.value
                        )}
                        onChange={handleChange}
                      />
                      <label
                        className="adjustTextSize mt-1 adjustSpace form-check-label"
                        htmlFor={option.value}
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              )}
          </div>
        </Grid>
      );
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>  {/* ✅ Wrap in form */}
        <div className="mt-3">
        <div className="row justify-content-center">
          <div className="border border-5 rounded border-light-3 py-4 px-4 d-flex flex-column align-items-center justify-content-center col-12 col-md-6 col-lg-6">
            <h2
              className={`text-center colorApply ${
                isSmallScreen ? "h4" : "h3"
              }`}
            >
              {formHeading}
            </h2>
            <Grid container spacing={2} marginTop={"8px"}>
              {renderFields(fields, formData, errors)}
            </Grid>

            <Box
              className="py-4"
              display={"flex"}
              gap={2}
              justifyContent={"center"}
              mt={2}
            >
              {isShowCancel && (
                <Button
                  variant="outlined"
                  sx={{
                    color: "#333333",
                    borderColor: "#cccccc",
                    ":hover": {
                      backgroundColor: "#cccccc",
                      borderColor: "#cccccc",
                    },
                    width: "150px",
                    height: "50px",
                    fontSize: "13px",
                  }}
                  gap={4}
                  size="large"
                >
                  {secondIcon && secondIcon} {title2}
                </Button>
              )}
              <Button
                type="reset"
                variant="outlined"
                color="primary"
                size="large"
                fullWidth={false}
                disabled={isDisabled}
                onClick={resetFormValue}
              >
                Reset
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                startIcon={<FaCheck />}
                fullWidth={false}
                disabled={isDisabled}
                onClick={handleSubmit}
              >
                {title}
              </Button>
            </Box>
          </div>
        </div>
      </div>
      </form>
    </>
  );
};

export default Form;
